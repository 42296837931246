"use client";

import { twMerge } from "tailwind-merge";
import React, { FC, useState } from "react";
import PrimaryButton from "@/components/Buttons/Primary/Button";
import IconButton from "@/components/Buttons/Icon/Button";
import { useLocale, useTranslations } from "next-intl";
import dynamic from "next/dynamic";
import MenuIcon from "public/icons/menu.svg";
const ParamsLink = dynamic(
  () => import("@/components/pages/Landings/ParamsLink"),
);

import { CategoryDocument } from "@/prismicio-types";
import { usePathname } from "@/navigation";
import { useParams } from "next/navigation";

type Props = {
  categories: CategoryDocument<string>[];
  noPosts?: boolean;
};

const MobileNavigation: FC<Props> = ({ categories, noPosts }) => {
  const t = useTranslations();
  const locale = useLocale();
  const pathname = usePathname();
  const params = useParams();
  const [isMenuOpened, openMenu] = useState(false);

  return (
    <>
      <IconButton
        onClick={() => openMenu(!isMenuOpened)}
        className="block md:hidden ml-[10px]"
        icon={<MenuIcon className="text-black-400" />}
      />
      {isMenuOpened && (
        <div className="flex md:hidden absolute flex-col items-center border-b-[1px] border-beige gap-[16px] top-[64px] left-0 w-full bg-black-100 p-[20px]">
          <ParamsLink
            className={twMerge(
              "text-[18px] font-bold pb-[5px] border-b-[2px] border-transparent",
              pathname === "/blog" && "text-primary border-primary",
            )}
            href="/blog"
          >
            {t("Blog.overview")}
          </ParamsLink>
          {!noPosts &&
            categories?.length > 0 &&
            categories
              .sort(
                (a, b) =>
                  // @ts-ignore
                  a.data.order - b.data.order,
              )
              .map((category) => (
                <ParamsLink
                  key={category.uid}
                  className={twMerge(
                    "text-[18px] font-bold",
                    params.slug ===
                      (locale === "en"
                        ? category.data.slugEN
                        : category.data.slugDE) &&
                      "text-primary border-b-[2px] border-primary pb-[5px]",
                  )}
                  href={`/blog/category/${
                    locale === "en"
                      ? category.data.slugEN
                      : category.data.slugDE
                  }`}
                  page={1}
                >
                  {category.data.name}
                </ParamsLink>
              ))}
          <ParamsLink href="/register">
            <PrimaryButton
              isShineHover
              className="p-[8px_20px] rounded-[8px]"
              label={t("Blog.earnWithRewards")}
            />
          </ParamsLink>
        </div>
      )}
    </>
  );
};

export default MobileNavigation;
