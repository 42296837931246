"use client";

import React, { FC } from "react";
import { Link } from "@/navigation";
import { useParams, useSearchParams } from "next/navigation";

type ParamsLinkType = {
  className?: string;
  children: React.ReactNode;
  href?: string;
  locale?: "en" | "de";
  hash?: string;
  page?: number;
  isDisabled?: boolean;
};
const ParamsLink: FC<ParamsLinkType> = ({
  className,
  children,
  href = "",
  locale,
  hash = "",
  page,
  isDisabled = false,
}) => {
  const params = useParams();
  const allQueryParams = useSearchParams();
  const queryParams: any = {};

  if (allQueryParams.get("utm_source")) {
    queryParams.utm_source = allQueryParams.get("utm_source");
  }
  if (allQueryParams.get("utm_medium")) {
    queryParams.utm_medium = allQueryParams.get("utm_medium");
  }
  if (allQueryParams.get("utm_campaign")) {
    queryParams.utm_campaign = allQueryParams.get("utm_campaign");
  }
  if (allQueryParams.get("click_id")) {
    queryParams.click_id = allQueryParams.get("click_id");
  }
  if (allQueryParams.get("oid")) {
    queryParams.click_id = allQueryParams.get("oid");
  }
  if (page) {
    queryParams.page = page;
  }

  return (
    <Link
      className={className}
      locale={locale}
      disabled={isDisabled}
      href={{
        pathname: href,
        hash: hash,
        params: params,
        query: queryParams,
      }}
    >
      {children}
    </Link>
  );
};

export default ParamsLink;
