"use client";

import React, { FC, ReactNode } from "react";
import useOutsideCheck from "@/hooks/useOutsideCheck";
import useAppHeight from "@/hooks/useAppHeight";

type Props = {
  children: ReactNode;
};

const Layout: FC<Props> = ({ children }) => {
  useOutsideCheck();
  useAppHeight();

  return (
    <div className="bg-white min-h-[100vh] flex flex-col grow">{children}</div>
  );
};

export default Layout;
