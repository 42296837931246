import(/* webpackMode: "eager" */ "/vercel/path0/components/Buttons/Primary/styles.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["AllPosts"] */ "/vercel/path0/components/pages/Blog/AllPosts.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CategoryPosts"] */ "/vercel/path0/components/pages/Blog/CategoryPosts.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/pages/Blog/DesktopNavigation.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/pages/Blog/MobileNavigation.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/pages/Landings/LanguageSelect.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/pages/Landings/ParamsLink.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/Spinner/Spinner.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["PrismicNextImage"] */ "/vercel/path0/node_modules/@prismicio/next/dist/PrismicNextImage.js");
;
import(/* webpackMode: "eager", webpackExports: ["PrismicNextLink"] */ "/vercel/path0/node_modules/@prismicio/next/dist/PrismicNextLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["PrismicPreviewClient"] */ "/vercel/path0/node_modules/@prismicio/next/dist/PrismicPreviewClient.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/slick-carousel/slick/slick.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/slick-carousel/slick/slick-theme.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/icons/appleStoreDownload.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/icons/facebook.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/icons/googleStoreDownload.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/icons/instagram.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/icons/x.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/logo.svg");
