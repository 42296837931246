"use client";

import { twMerge } from "tailwind-merge";
import React, { FC } from "react";
import { useLocale, useTranslations } from "next-intl";
import dynamic from "next/dynamic";
const ParamsLink = dynamic(
  () => import("@/components/pages/Landings/ParamsLink"),
);
import { CategoryDocument } from "@/prismicio-types";
import { useParams } from "next/navigation";
import { usePathname } from "@/navigation";

type Props = {
  categories: CategoryDocument<string>[];
};

const DesktopNavigation: FC<Props> = ({ categories }) => {
  const locale = useLocale();
  const t = useTranslations();
  const params = useParams();
  const pathname = usePathname();

  return (
    <>
      <ParamsLink
        className={twMerge(
          "relative text-[14px] font-semibold text-black-500 hover:text-primary transition-all",
          pathname === "/blog" && "text-primary",
        )}
        href="/blog"
      >
        {t("Blog.overview")}
        {pathname === "/blog" && (
          <div className="absolute bottom-[-5px] h-[2px] w-full bg-primary" />
        )}
      </ParamsLink>
      {categories
        .sort(
          (a, b) =>
            // @ts-ignore
            a.data.order - b.data.order,
        )
        .map((category) => (
          <ParamsLink
            key={category.uid}
            className={twMerge(
              "relative text-[14px] font-semibold text-black-500 hover:text-primary transition-all",
              params.slug ===
                (locale === "en"
                  ? category.data.slugEN
                  : category.data.slugDE) && "text-primary",
            )}
            href={`/blog/category/${
              locale === "en" ? category.data.slugEN : category.data.slugDE
            }`}
          >
            {category.data.name}
            {params.slug ===
              (locale === "en"
                ? category.data.slugEN
                : category.data.slugDE) && (
              <div className="absolute bottom-[-5px] h-[2px] w-full bg-primary" />
            )}
          </ParamsLink>
        ))}
    </>
  );
};

export default DesktopNavigation;
