"use client";

import React, { FC } from "react";
import { BlogCard } from "@/components/Blog/BlogCard/BlogCard";
import { useTranslations } from "next-intl";
import LottieComponent from "@/components/Lottie/Lottie";

type Props = {
  latestPost: any;
  otherLatestPost: any;
};

export const AllPosts: FC<Props> = ({ latestPost, otherLatestPost }) => {
  const t = useTranslations();

  return (
    <div>
      <p className="mb-[8px] text-[32px] leading-[38px] font-bold text-center md:text-left">
        {t("Blog.welcome")}
      </p>
      <p className="mb-[32px] text-[18px] leading-[24px] text-black-400 text-center md:text-left">
        {t("Blog.welcomeSubTitle")}
      </p>
      {latestPost || otherLatestPost.length > 0 ? (
        <div className="flex flex-col gap-[16px] md:gap-[32px]">
          <BlogCard isFullWidth post={latestPost} />
          <div className="grid items-stretch grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-[16px] md:gap-[32px]">
            {otherLatestPost.map((post: any) => (
              <BlogCard key={post.id} post={post} />
            ))}
          </div>
        </div>
      ) : (
        <div className="flex flex-col items-center gap-[8px] w-full md:mt-[60px]">
          <div className="block md:hidden rounded-[8px] overflow-hidden">
            <LottieComponent
              getAnimationData={() =>
                import("public/lottie/MyGamesCompletedNoResultsMobile.json")
              }
              id="MyGamesCompletedNoResultsMobile"
            />
          </div>
          <div className="hidden md:block order-1 md:order-3 rounded-[8px] overflow-hidden">
            <LottieComponent
              getAnimationData={() =>
                import("public/lottie/MyGamesCompletedNoResultsDesktop.json")
              }
              id="MyGamesCompletedNoResultsDesktop"
            />
          </div>
        </div>
      )}
    </div>
  );
};
