"use client";

import React, { FC } from "react";
import { BlogCard } from "@/components/Blog/BlogCard/BlogCard";
import { useLocale, useTranslations } from "next-intl";
import Button from "@/components/Buttons/Text/Button";
import dynamic from "next/dynamic";
const ParamsLink = dynamic(
  () => import("@/components/pages/Landings/ParamsLink"),
);
import { twMerge } from "tailwind-merge";
import styles from "@/components/pages/Balance/styles.module.css";
import wrapperStyles from "@/components/pages/Blog/styles.module.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { PrismicNextImage } from "@prismicio/next";

type Props = {
  categoryGroup: any;
};

export const CategoryPosts: FC<Props> = ({ categoryGroup }) => {
  const t = useTranslations();
  const locale = useLocale();
  const mobileSettings = {
    initialSlide: 0,
    dots: true,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  if (categoryGroup.posts.length === 0) return null;

  return (
    <div
      className={twMerge(
        "mb-[20px] md:mb-0",
        wrapperStyles.categoryPostsWrapper,
      )}
    >
      <div className="flex items-center flex-col md:flex-row justify-between gap-[20px] md:gap-[100px] mb-[16px]">
        <h2 className="flex items-center gap-[10px] text-[22px] md:text-[32px] text-center md:text-left font-bold">
          <div className="hidden md:block relative w-[36px] h-[36px] rounded-full overflow-hidden shrink-0">
            <PrismicNextImage
              field={categoryGroup.data.icon}
              className="object-cover"
              fill={true}
              sizes="100%"
            />
          </div>
          {categoryGroup.data.name}
        </h2>
        {categoryGroup.posts.length === 3 && (
          <ParamsLink
            href={`/blog/category/${
              locale === "en"
                ? categoryGroup.data.slugEN
                : categoryGroup.data.slugDE
            }`}
            page={1}
          >
            <Button
              className="border-[1px] md:border-[0px] p-[8px_20px] md:p-0 rounded-[12px] md:hover:underline"
              label={t("Blog.seeAll")}
            />
          </ParamsLink>
        )}
      </div>
      <Slider
        {...mobileSettings}
        className={twMerge(
          "relative !block md:!hidden z-1",
          styles.slickWrapper,
        )}
      >
        {categoryGroup.posts.map((post: any) => (
          <BlogCard className="h-full" key={post.id} post={post} />
        ))}
      </Slider>
      <div className="hidden md:grid items-stretch grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-[16px] md:gap-[32px]">
        {categoryGroup.posts.map((post: any) => (
          <BlogCard key={post.id} post={post} />
        ))}
      </div>
    </div>
  );
};
