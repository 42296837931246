"use client";

import { useQuery } from "react-query";
import { Suspense } from "react";
import { Player } from "@lottiefiles/react-lottie-player";
interface LottieProps<T extends Record<string, unknown>> {
  getAnimationData: () => Promise<T>;
  className?: string;
  id: string;
}

function LottieComponent<T extends Record<string, unknown>>({
  getAnimationData,
  id,
  className,
}: LottieProps<T> & Omit<any, "animationData">) {
  const { data } = useQuery({
    queryKey: [id],
    queryFn: async () => {
      void import("@lottiefiles/react-lottie-player");
      return getAnimationData();
    },
    enabled: typeof window !== "undefined",
  });

  if (!data) return null;

  return (
    <Suspense fallback={<></>}>
      <Player className={className} autoplay speed={1} src={data} loop />
    </Suspense>
  );
}

export default LottieComponent;
