"use client";

import { Listbox, Transition } from "@headlessui/react";
import React, { FC, Fragment } from "react";

import { twMerge } from "tailwind-merge";
import ParamsLink from "@/components/pages/Landings/ParamsLink";

import { usePathname } from "@/navigation";
import { useTranslations } from "next-intl";

export type Option = {
  name: string;
  value: "en" | "de";
  icon: string;
  pathname?: string;
};

type Props = {
  className?: string;
  selectedOption: Option | null;
  options: Option[];
};

const LanguageSelect: FC<Props> = ({
  className = "",
  selectedOption,
  options,
}) => {
  const currentPathname = usePathname();
  const t = useTranslations();

  return (
    <div className={twMerge("relative", className)}>
      <Listbox value={selectedOption}>
        <Listbox.Button
          className={({ open }) =>
            `
              ${"relative rounded-[8px] border border-black-300 cursor-pointer bg-white h-[42px] py-[6px] px-[11px] text-[14px] text-left transition-colors duration-100 !outline-0 !outline-offset-0 truncate"}
              ${"hover:border-primary hover:outline hover:!outline-[1px] hover:outline-primary"}
              ${"focus:border-primary focus:outline focus:!outline-[1px] focus:outline-primary"}
              ${
                open && "!border-primary outline !outline-[1px] outline-primary"
              }
            `
          }
        >
          <span className={twMerge("text-black-500 text-[18px]")}>
            {selectedOption ? selectedOption.icon : ""}
          </span>
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options
            className={twMerge(
              "absolute right-0 mt-[6px] z-50 w-[100px] max-h-[200px] overflow-auto rounded-[8px] bg-white py-2 text-base shadow-lg",
            )}
          >
            {options.map((option) => (
              <ParamsLink
                key={option.value}
                href={option.pathname || currentPathname}
                locale={option.value}
              >
                <Listbox.Option
                  key={option.name}
                  value={option}
                  onClick={(e) => e.stopPropagation()}
                  className={({ active }) =>
                    `relative cursor-pointer select-none py-2 px-[8px] text-[14px] transition-colors duration-100 ${
                      active && "bg-primary-100"
                    }`
                  }
                >
                  <span className="block truncate">
                    <span className="relative top-[2px] text-[18px]">
                      {option.icon}
                    </span>
                    &nbsp;
                    {t(option.name)}
                  </span>
                </Listbox.Option>
              </ParamsLink>
            ))}
          </Listbox.Options>
        </Transition>
      </Listbox>
    </div>
  );
};

export default LanguageSelect;
