import Button from "@/components/Buttons/Secondary/Button";
import React from "react";
import { calculateDaysAgo } from "@/utils";
import { useLocale, useTranslations } from "next-intl";
import { format, isToday, isYesterday } from "date-fns";
import { twMerge } from "tailwind-merge";
import dynamic from "next/dynamic";
const ParamsLink = dynamic(
  () => import("@/components/pages/Landings/ParamsLink"),
);
import { PrismicNextImage } from "@prismicio/next";

export const BlogCard = ({
  post,
  isFullWidth,
  isHalfWidth,
  className,
}: {
  post: any;
  isFullWidth?: boolean;
  isHalfWidth?: boolean;
  className?: string;
}): JSX.Element => {
  const t = useTranslations();
  const locale = useLocale();
  const publicationDaysAgo = calculateDaysAgo(post.last_publication_date);

  return (
    <ParamsLink
      href={`/blog/${locale === "en" ? post.data.slugEN : post.data.slugDE}`}
      className={twMerge(
        "grid grid-cols-1 grid-rows-[auto_1fr] min-h-[350px] gap-[12px] rounded-[16px] bg-white border-[1px] border-beige p-[16px] hover:shadow-md transition-all",
        isFullWidth &&
          "md:grid-cols-[4fr_3fr] md:grid-rows-1 md:gap-[40px] md:pr-[40px] md:items-center",
        className,
      )}
    >
      <div
        className={twMerge(
          "relative w-[100%] h-[185px] bg-black-200 rounded-[16px] overflow-hidden",
          isHalfWidth && "md:h-[290px]",
          isFullWidth && "md:h-[315px]",
        )}
      >
        <PrismicNextImage
          field={post.data.featured_image}
          priority={isFullWidth}
          className="object-cover"
          fill={true}
          sizes="(max-width: 768px) 100vw, 33vw"
        />
      </div>
      <div className="flex flex-col justify-between gap-[12px]">
        <div className="flex flex-col gap-[12px] grow">
          <div className="flex items-center flex-wrap gap-[10px]">
            {post.data.categories.map((category: any) => (
              <Button
                key={category.category.data.uid}
                className="p-[6px_8px] text-[14px] rounded-[8px] bg-white border-beige"
                label={category.category.data.name}
                noEvents
                beforeIcon={
                  <PrismicNextImage
                    field={category.category.data.icon}
                    width={20}
                    height={20}
                    sizes="(max-width: 768px) 20px, 20px"
                  />
                }
              />
            ))}
          </div>
          <p
            className={twMerge(
              "line-clamp-3 text-[22px] leading-[28px] md:leading-[24px] md:text-[18px] font-bold",
              isHalfWidth && "md:text-[22px]",
              isFullWidth && "md:text-[32px] md:leading-[38px]",
            )}
          >
            {post.data.title}
          </p>
          <p
            className={twMerge(
              "line-clamp-3 text-[16px] md:text-[14px] text-black-400",
              isFullWidth && "md:text-[16px]",
            )}
          >
            {post.data.description}
          </p>
        </div>
        <p className="text-black-400 font-bold text-[14px]">
          {isToday(new Date(post.last_publication_date)) && t("Blog.today")}
          {isYesterday(new Date(post.last_publication_date)) &&
            t("Blog.yesterday")}
          {publicationDaysAgo >= 2 &&
            publicationDaysAgo <= 7 &&
            `${t("Blog.daysAgo", { days: publicationDaysAgo })}`}
          {publicationDaysAgo > 7 &&
            format(new Date(post.last_publication_date), "dd.MM.yyyy")}
        </p>
      </div>
    </ParamsLink>
  );
};
